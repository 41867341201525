import cellRenderer from '@/components/globals/dataTable/tableCellComponent.js';
import moment from 'moment-timezone';
import selectLevelCell from '@/pages/budget-planner-v2/create-plan/steps/inputBudgetPlan/tableCells/selectLevelCell.vue';
import monthCell from '@/pages/budget-planner-v2/create-plan/steps/inputBudgetPlan/tableCells/monthCell.vue';

export const findRemainingMonths = (monthsShort) => {
  const currentMonth = moment().format('MMM');
  const findRemaingMonthIndex = monthsShort.findIndex(
    (element) => element === currentMonth
  );
  return monthsShort
    .slice(findRemaingMonthIndex, monthsShort.length)
    .map((item) => item.toLowerCase());
};

export const isBudgetPlanInputValid = (value) => {
  return /^\+?(0|[1-9]\d*)$/.test(value);
};

export const isCellEnabled = (vueRef, params, remainingMonths) => {
  const name = params?.data?.parentName || params?.data?.name;
  const dropdown =
    vueRef.$store.getters['budgetPlannerOmni/getDropdownSelectionMap']?.[name];
  const levelName = params?.data?.levelName;
  const condition1 = levelName === dropdown?.title;
  const condition2 = !(
    params.data.position === 0 &&
    (name === 'null' || name === null)
  );
  const condition3 = remainingMonths.includes(params?.colDef?.field);
  const condition4 = !(
    params.data.position === 1 &&
    params.data.ui_label === 'Rest' &&
    (params?.data?.parentName === 'null' || params?.data?.parentName === null)
  );
  let reason = '';
  if (condition2 && condition3) {
    reason = 'Expand/collapse level to input plan';
  } else if (condition3) {
    reason = 'Can not edit cell, entity is uncategorised';
  } else if (condition2) {
    reason = 'Can not edit cell, entity month is in the past';
  }
  return {
    value: condition1 && condition2 && condition3 && condition4,
    reason
  };
};

const getEntitiesColumnName = (selectedLevels) => {
  let entityName = '';
  for (let key in selectedLevels) {
    if (selectedLevels[key])
      entityName += ' > ' + selectedLevels[key].dimensionLabel;
  }
  entityName = entityName.slice(3);
  return entityName;
};

const monthsColumns = (isNextYearPlan) => {
  const monthsShort = moment.monthsShort();
  const remainingMonths = isNextYearPlan
    ? monthsShort.map((month) => month.toLowerCase())
    : findRemainingMonths(monthsShort);
  const months = [];
  for (const month of monthsShort) {
    months.push({
      field: month.toLowerCase(),
      headerComponentFramework: 'genericTableHeader',
      headerName: month,
      title: month,
      cellRendererFramework: 'DynamicCellComponent',
      cellStyle: (params) => {
        const vueRef = params.context.componentParent;
        const cellEnabled = isCellEnabled(vueRef, params, remainingMonths);
        const disableCellDropdownSelection = !cellEnabled.value;
        const commonStyle = { padding: '0px', margin: '0px' };
        if (disableCellDropdownSelection) {
          return { backgroundColor: '#f6f7fa', ...commonStyle };
        }
        return commonStyle;
      },
      pinned: false,
      valueParser: (params) => {
        const monthValue = params.newValue;
        const validateValue = isBudgetPlanInputValid(monthValue);
        if (!validateValue) {
          const vueRef = params.context.componentParent;
          vueRef.$snackbar.open({
            message: `Invalid value entered for ${month}, value has been reset to 'NA'`,
            duration: 3000,
            buttonColor: '#f5d908',
            actionText: ''
          });
          return null;
        }
        return monthValue;
      },
      editable: (params) => {
        const vueRef = params.context.componentParent;
        const cellEnabled = isCellEnabled(vueRef, params, remainingMonths);
        return cellEnabled?.value;
      },
      headerComponentParams: {
        enableSorting: true,
        alignHeader: 'right',
        toolTipText: month,
        keyType: 'string'
      },
      suppressSizeToFit: true,
      minWidth: 50,
      width: 80,
      cellRendererParams: {
        component: monthCell,
        eventMap: {},
        paramsToProps: (params) => {
          return {
            remainingMonths,
            params,
            isCellEnabled
          };
        }
      }
    });
  }
  return months;
};

export const columns = (selectedLevels, isNextYearPlan = false) => {
  const name = getEntitiesColumnName(selectedLevels);
  return [
    {
      field: 'selectedLevelDropdown',
      headerComponentFramework: 'genericTableHeader',
      headerName: 'Distribution Level',
      title: 'Distribution Level',
      cellRendererFramework: 'DynamicCellComponent',
      pinned: true,
      headerComponentParams: {
        enableSorting: false,
        toolTipText: 'Selected distribution levels',
        keyType: 'string'
      },
      suppressSizeToFit: true,
      width: 150,
      cellRendererParams: {
        component: selectLevelCell,
        eventMap: {
          selectionChanged: 'selectionChanged'
        },
        paramsToProps: (params) => {
          return {
            dropdownItems:
              params.data.ui_label === 'Uncategorized Campaign(s)'
                ? [params?.data?.levelDropdown?.[0]]
                : params?.data?.levelDropdown,
            selectedLevelDropdown: params.data.selectedLevelDropdown,
            params
          };
        }
      },
      eventMap: {}
    },
    {
      field: 'ui_label',
      cellStyle: (params) => {
        if (params?.data?.position > 0) {
          return { 'padding-left': '42px' };
        }
        return null;
      },
      headerComponentFramework: 'genericTableHeader',
      cellRendererParams: {
        keyType: 'string'
      },
      cellRenderer: cellRenderer,
      headerName: name,
      pinned: true,
      title: name,
      headerComponentParams: {
        enableSorting: true,
        toolTipText: name,
        keyType: 'string'
      },
      keyOrder: 4,
      suppressSizeToFit: false,
      minWidth: 150
    },
    ...monthsColumns(isNextYearPlan)
  ];
};
