var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"u-display-flex u-width-100 u-height-100 u-flex-align-items-center u-flex-justify-content-center u-spacing-p-xs",class:[
    _vm.disableCellDropdownSelection
      ? 'cell-grey-color-bp2'
      : 'u-color-grey-base cell-hevor-bp2',
    _vm.cellValue
      ? 'u-flex-justify-content-flex-end'
      : 'u-flex-justify-content-center'
  ],attrs:{"title":_vm.disableCellDropdownSelection
      ? _vm.disableCellDropdownSelection && _vm.cellEnabled.reason
      : 'Click to enter value'}},[_vm._v(" "+_vm._s(_vm.cellValue ? _vm.currency : '')+_vm._s(_vm.cellValue ? _vm.numberFormatter(_vm.cellValue) : '-')+" ")])}
var staticRenderFns = []

export { render, staticRenderFns }