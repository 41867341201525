<template>
  <div
    :title="
      disableCellDropdownSelection
        ? disableCellDropdownSelection && cellEnabled.reason
        : 'Click to enter value'
    "
    class="u-display-flex u-width-100 u-height-100 u-flex-align-items-center u-flex-justify-content-center u-spacing-p-xs"
    :class="[
      disableCellDropdownSelection
        ? 'cell-grey-color-bp2'
        : 'u-color-grey-base cell-hevor-bp2',
      cellValue
        ? 'u-flex-justify-content-flex-end'
        : 'u-flex-justify-content-center'
    ]"
  >
    {{ cellValue ? currency : ''
    }}{{ cellValue ? numberFormatter(cellValue) : '-' }}
  </div>
</template>

<script>
import Vue from 'vue';
import { cloneDeep } from 'lodash';
export default {
  props: {
    params: {
      default: function () {
        return {};
      },
      type: Object
    },
    isCellEnabled: {
      type: Function,
      default: function () {}
    },
    remainingMonths: {
      default: () => [],
      type: Array
    }
  },
  data() {
    return {
      currency: Vue.prototype.$currency
    };
  },
  computed: {
    cellValue() {
      const value = parseInt(this.params.data[this.params?.colDef?.field]);
      let newValue;
      if (isNaN(value)) {
        newValue = null;
      } else {
        newValue = value.toString();
      }
      return newValue;
    },
    cellEnabled() {
      return this.isCellEnabled(this, this.params, this.remainingMonths);
    },
    disableCellDropdownSelection() {
      return !this.cellEnabled.value;
    }
  },
  methods: {
    numberFormatter(value) {
      const newValue = cloneDeep(parseInt(value));
      return newValue?.toLocaleString();
    }
  }
};
</script>
