<template>
  <div
    class="u-display-flex u-width-100"
    :class="'is-disabled' && isDisabled"
  >
    <div
      v-if="dropdownItems"
      class="u-display-flex u-width-100"
    >
      <rb-select
        class="u-display-flex u-width-100 border-value u-spacing-p-s"
        :send-details="true"
        :disabled="isDisabled"
        :options="dropdownItems"
        :on-close="onRollupChangeSelected"
      >
        <div
          slot="trigger"
          class="u-display-flex u-font-size-7 u-flex-justify-content-space-between u-flex-align-items-center u-cursor-pointer"
        >
          <div class="u-text-overflow-ellipsis u-color-grey-base">
            {{ (selectedLevelDropdown && selectedLevelDropdown.title) || 'NA' }}
          </div>
          <rb-icon
            class="u-flex-0 rb-icon--medium u-spacing-ml-xs u-color-grey-lighter"
            :icon="'caret-down'"
          />
        </div>
      </rb-select>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    isDisabled: {
      type: Boolean,
      default: false
    },
    params: {
      type: Object,
      default: function () {
        return {};
      }
    },
    dropdownItems: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    selectedLevelDropdown() {
      return this.params.data.selectedLevelDropdown;
    }
  },
  methods: {
    onRollupChangeSelected(n, data) {
      if (data?.length) {
        this.$emit('selectionChanged', {
          params: this.params,
          selectedValue: data[0]
        });
      }
    }
  }
};
</script>
