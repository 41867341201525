<template>
  <div>
    <div class="u-spacing-pt-xxl">
      <budgetPlannerHeader :step="step" />
    </div>
    <div
      class="u-flex-direction-column u-display-flex u-spacing-pr-xxl u-position-relative budget-planner-v2"
      :class="
        budgetPlanInputTable.load && 'u-width-90 budget-plan-table-height'
      "
    >
      <Loader
        :loading="budgetPlanInputTable.load"
        class="fill--parent"
        :color="'#3fadf7'"
        :thickness="'.2rem'"
      />

      <form
        v-if="true"
        class="u-position-relative u-display-flex u-spacing-mr-s u-spacing-mb-m u-width-480px"
        @submit.prevent="searchSubmit"
      >
        <rb-input
          v-model="search"
          :placeholder="placeholder"
          data-cy="SearchInput"
          class="search__input u-width-100 u-border-left-none"
          :rounded="false"
        />

        <span
          class="u-position-absolute search-icon-bp-input u-display-flex"
          data-cy="submitBtn"
          @click="searchSubmit"
        >
          <rb-icon
            icon="search"
            class="search__input-icon rb-icon--medium"
            data-cy="searchIcon"
          />
        </span>
      </form>
      <div
        class="bp-preview-table-container"
        @paste="onPasteListener"
      >
        <rb-insights-table
          v-if="!budgetPlanInputTable.load"
          :key="tableKey"
          class="budget-plan-table-height table-max-width"
          :config="{}"
          :table-row="budgetPlanSearchedTable"
          :table-column="columnsDef"
          :stop-editing-when-grid-loses-focus="true"
          :grid-options="gridOptions"
          :single-click-edit="true"
          :enable-cell-text-selection="true"
          :row-height="50"
          :custom-has-expand-column-order="1"
          :levels="levels"
          :has-expand="true"
          :enable-client-side-sorting="false"
          :sorting-change-event="sortInputTableEvent"
          :enable-server-side-sorting="true"
          :show-pagination-options="false"
          table-id="budget-preview-master"
          :get-current-instance="getTableInstance"
          @cellEditingStopped="cellEditingStopped"
          @tableExpandChangeTriggered="onExpand"
        />
      </div>
    </div>
    <budgetPlannerFooter
      :disable-right-btn="
        budgetPlanInputTable.error || budgetPlanInputTable.load
      "
      :show-left-btn="true"
      btn-right-text="Next"
      class="u-spacing-pt-m"
      custom-class-right="fs-budget-plan-move-to-input-budget-plan-step"
      @onClickBtnRight="nextStep"
      @onClickBtnLeft="previousStep"
    />
  </div>
</template>

<script>
import { sortBudgetPlanInputRows } from '@/pages/budget-planner-v2/create-plan/store/helpers/transformers.js';
import {
  updateClonedPlan,
  deleteKeysInRow,
  convertExecutionStrategyToTempRow,
  ignoreAGValues
} from '@/pages/budget-planner-v2/create-plan/steps/inputBudgetPlan/helpers.js';
import {
  columns,
  isCellEnabled,
  isBudgetPlanInputValid,
  findRemainingMonths
} from '@/pages/budget-planner-v2/create-plan/steps/inputBudgetPlan/inputBudgetPlan.js';
import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';
import { eventBus } from '@/utils/services/eventBus';
import budgetPlannerHeader from '@/components/ams/budgetPlanner/steps/common/header.vue';
import Loader from '@/components/basic/loader';
import budgetPlannerFooter from '@/components/ams/budgetPlanner/steps/common/footer.vue';
import Vue from 'vue';
import utils from '@/utils/helpers';

export default {
  components: {
    Loader,
    budgetPlannerHeader,
    budgetPlannerFooter
  },
  props: {
    step: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data() {
    return {
      searchText: '',
      sortInputTableEvent: 'budgetOptimiserOmniSort',
      search: '',
      columnsDef: [],
      delimiter: '|-|',
      isExpanded: false,
      tableKey: 0,
      tempRowData: {},
      levels: [
        {
          level: 1,
          getter: 'budgetPlannerOmni/getBudgetPlanLevel1',
          action: 'budgetPlannerOmni/fetchBudgetPlanLevelData'
        }
      ],
      tableInstance: null,
      gridOptions: {
        suppressScrollOnNewData: true,
        domLayout: 'normal',
        onCellEditingStopped: this.cellEditingStopped,
        context: {
          componentParent: this
        }
      }
    };
  },
  computed: {
    planFetchCount() {
      return this.$store.getters['budgetPlannerOmni/getPlanFetchCount'];
    },
    getInputTableSort() {
      return this.$store.getters['budgetPlannerOmni/getInputTableSort'];
    },
    getInputTableSortInputTableDirection() {
      return this.$store.getters[
        'budgetPlannerOmni/getInputTableSortInputTableDirection'
      ];
    },
    budgetPlanSearchedTable() {
      const searchkey = this.searchText?.trim().toLowerCase();
      let table = this.budgetPlanInputTable.rows;
      if (searchkey.length) {
        table = table.filter((row) => {
          if (row.searchKey.includes(searchkey)) {
            for (let key in row) {
              if (key.includes('ag-') && !ignoreAGValues.includes(key)) {
                delete row[key];
              }
            }
          }
          return row.searchKey.includes(searchkey);
        });
      }
      return table;
    },
    budgetPlanInputTable() {
      return this.$store.getters[
        'budgetPlannerOmni/getBudgetPlanInputTableRows'
      ];
    },
    dropdownSelection() {
      return this.$store.getters['budgetPlannerOmni/getDropdownSelectionMap'];
    },
    selectedLevels() {
      return this.$store.getters['budgetPlannerOmni/getSelectedLevels'];
    },
    placeholder() {
      return (
        'Search for ' +
        Object.values(this.selectedLevels || {})
          .map((item) => item?.dimensionLabel?.toLowerCase())
          .join(' or ')
      );
    }
  },
  watch: {
    planFetchCount: {
      handler() {
        convertExecutionStrategyToTempRow(
          this.budgetPlanInputTable.rows,
          this.tempRowData
        );
      },
      immediate: true
    },
    'tableInstance.localTableRow': {
      handler(newValue, oldValue) {
        let isExpanded = false;
        for (const row of newValue) {
          if (row.isExpanded) {
            isExpanded = true;
            break;
          }
        }
        if (isExpanded === this.isExpanded) {
          return;
        }
        this.isExpanded = isExpanded;
        this.updateChildValues();
      },
      deep: true
    },
    budgetPlanInputTable: {
      handler(newValue) {
        if (newValue?.error) {
          this.$snackbar.open({
            message: 'Something went wrong',
            actionText: '',
            duration: 5000
          });
        }
      },
      deep: true
    }
  },
  created() {
    const isNextYearPlan = this.$route.query.nextYearPlan;
    this.allColumnsDef = columns(this.selectedLevels, isNextYearPlan);
    this.columnsDef = this.allColumnsDef;
    const vueInstance = new Vue();
    const automationsConfigEnabled = vueInstance.$options.filters.config_check(
      'feature.pages.boV2_automations'
    )?.enable;
    this.$store.dispatch('budgetPlannerOmni/fetchLatestBudgetPlan', {
      isNextYearPlan,
      isAutomationsEnabled:
        automationsConfigEnabled && utils.internalUserCheck(window.user)
    });
    eventBus.$on(this.sortInputTableEvent, (column) => {
      this.$store.commit('budgetPlannerOmni/SORT_INPUT_TABLE', {
        direction: column.sort,
        sort: column.colId
      });
      let clonedPlan = cloneDeep(this.budgetPlanInputTable.rows);
      for (let row of clonedPlan) {
        deleteKeysInRow(row, ignoreAGValues);
        this.isExpanded = false;
      }
      clonedPlan = sortBudgetPlanInputRows(
        clonedPlan,
        this.getInputTableSort,
        this.getInputTableSortInputTableDirection
      );

      this.$store.dispatch(
        'budgetPlannerOmni/updateBudgetPlanInputTableRows',
        clonedPlan
      );
    });
  },
  destroyed() {
    eventBus.$off(this.sortInputTableEvent);
  },
  mounted() {
    this.restoreTempRows();
  },
  methods: {
    restoreTempRows() {
      const months = moment.monthsShort().map((item) => item.toLowerCase());
      this.convertInputBudgetPalnToTempRow(
        this.budgetPlanInputTable.rows,
        months
      );
    },
    convertInputBudgetPalnToTempRow(rows, months) {
      for (let row of rows) {
        for (let month of months) {
          if (row[month] >= 0) {
            if (!this.tempRowData[row.name]) {
              this.tempRowData[row.name] = {};
            }
            this.tempRowData[row.name][month] = row[month];
          }
        }
        if (row?.nextLevel.length) {
          this.convertInputBudgetPalnToTempRow(row?.nextLevel, months);
        }
      }
    },
    searchSubmit() {
      this.updatePlanOnStepChange(true);
      this.searchText = this.search;
    },
    previousStep() {
      this.updatePlanOnStepChange();
      this.$emit('prevStep', {});
    },
    updatePlanOnStepChange(search = false) {
      const clonedPlan = cloneDeep(this.budgetPlanInputTable.rows);
      updateClonedPlan(
        clonedPlan,
        this.tempRowData,
        ignoreAGValues,
        this.dropdownSelection
      );
      if (!search) {
        this.tempRowData = {};
      }
      this.$store.dispatch(
        'budgetPlannerOmni/updateBudgetPlanInputTableRows',
        clonedPlan
      );
    },
    nextStep() {
      this.updatePlanOnStepChange();
      this.$emit('nextStep', {});
    },
    getTableInstance(that) {
      this.tableInstance = that;
    },
    async copyAndGenerateExcelData() {
      const text = await navigator.clipboard.readText();
      const formatTextArray = text.split('\r\n');
      const finalArray = [];
      for (const item of formatTextArray) {
        if (item?.length) {
          finalArray.push(item.split('\t'));
        }
      }
      return finalArray;
    },
    generateMonthsArray(totalExcelItems, currentMonth) {
      const months = moment.monthsShort();
      const findIndex = months.findIndex(
        (item) => item.toLowerCase() === currentMonth
      );
      const monthArry = months.slice(findIndex, findIndex + totalExcelItems);
      const remainingMonths = monthArry.map((item) => item.toLowerCase());
      return { monthArry, remainingMonths };
    },
    pasteIntoValidCells(
      finalArray,
      i,
      k,
      monthArry,
      remainingMonths,
      localTableRow
    ) {
      for (let j = 0; j < finalArray?.[k]?.length; j++) {
        if (monthArry[j]) {
          const cellEnabled = isCellEnabled(
            this,
            {
              data: localTableRow[i],
              colDef: {
                field: monthArry[j].toLowerCase()
              }
            },
            remainingMonths
          );
          const isInputValid = isBudgetPlanInputValid(finalArray[k][j]);
          if (cellEnabled?.value && isInputValid) {
            localTableRow[i][monthArry[j]?.toLowerCase()] = finalArray[k][j];
            this.cellEditingStopped(
              {
                data: localTableRow[i],
                colDef: {
                  field: monthArry[j].toLowerCase()
                }
              },
              false
            );
          } else {
            this.$snackbar.open({
              message: !isInputValid
                ? 'One or more copied values are invalid'
                : 'Values were partially copied as some cells were not editable',
              duration: 3000,
              buttonColor: '#f5d908',
              actionText: ''
            });
            return false;
          }
        }
      }
      return true;
    },
    async onPasteListener(event) {
      event.stopPropagation();
      event.preventDefault();
      const focusedCell = this.gridOptions.api.getFocusedCell();
      const finalArray = await this.copyAndGenerateExcelData();
      const { monthArry, remainingMonths } = this.generateMonthsArray(
        finalArray[0]?.length,
        focusedCell?.column?.colDef?.field
      );
      const localTableRow = this.tableInstance.localTableRow;
      let row = cloneDeep(focusedCell.rowIndex);
      let k = 0;
      for (let i = 0; i < localTableRow.length; i++) {
        if (
          i === row &&
          localTableRow?.length >= row &&
          finalArray?.length >= k
        ) {
          const hasPasted = this.pasteIntoValidCells(
            finalArray,
            i,
            k,
            monthArry,
            remainingMonths,
            localTableRow
          );
          if (!hasPasted) {
            break;
          }
          row++;
          k++;
        }
      }
      this.forceUpdate();
    },
    selectionChanged({ params, selectedValue }) {
      this.$nextTick(() => {
        params.data.selectedLevelDropdown = selectedValue;
        params.data.isExpanded = false;
        this.updateRowDataOnSelectionChange(params, selectedValue);
        this.$store.dispatch(
          'budgetPlannerOmni/updateDropdownSelection',
          params.data
        );
        this.tableInstance.redrawTableRows([params]);
      });
    },

    updateRowDataOnSelectionChange(params, selectedValue) {
      const rowNames = [];
      const level1Name = params.data.name;
      rowNames.push(level1Name);
      const remainingMonths = findRemainingMonths(moment.monthsShort());
      for (const month of remainingMonths) {
        if (!this.tempRowData[level1Name]) {
          this.tempRowData[level1Name] = {};
        }
        this.tempRowData[level1Name][month] = null;
        for (const level of params?.data?.nextLevel || []) {
          const level2Name = level.name;
          rowNames.push(level2Name);
          if (!this.tempRowData[level2Name]) {
            this.tempRowData[level2Name] = {};
          }
          this.tempRowData[level2Name][month] = null;
        }
      }
      for (const row of this.tableInstance.localTableRow) {
        if (rowNames.includes(row.name)) {
          for (const month of remainingMonths) {
            row[month] = null;
          }
        }
      }
    },
    sumOfChildMonths(childRows, month) {
      let total = 0;
      let isTotalNullCount = 0;
      for (const row of childRows) {
        const cellValue = parseInt(this.tempRowData?.[row?.name]?.[month]);
        if (!cellValue) {
          isTotalNullCount++;
        }
        total += cellValue || 0;
      }
      return isTotalNullCount === childRows.length ? null : total;
    },
    onExpand() {
      this.updateChildValues();
    },
    updateChildValues(forceUpdate = true) {
      this.$nextTick(() => {
        let isValueUpdated = false;
        for (let row of this.tableInstance.localTableRow) {
          if (this.tempRowData[row.name]) {
            for (let key in this.tempRowData[row.name]) {
              row[key] = this.tempRowData[row.name][key];
              isValueUpdated = true;
            }
          }
        }
        if (isValueUpdated && forceUpdate) {
          this.forceUpdate();
        }
      });
    },
    cellEditingStopped(event, forceUpdate = true) {
      const currentRow = event.data;
      const currentColumn = event.colDef.field;
      const { name } = currentRow;
      if (!this.tempRowData[name]) {
        this.tempRowData[name] = {};
      }
      this.tempRowData[name][currentColumn] = currentRow[currentColumn];
      if (currentRow.position > 0) {
        this.updateLocalTableRow(currentRow, currentColumn);
        if (forceUpdate) {
          this.$nextTick(() => {
            this.forceUpdate();
          });
        }
      } else {
        this.clearTempRow(currentRow, currentColumn);
        this.updateChildValues(forceUpdate);
      }
    },
    clearTempRow(currentRow, currentColumn) {
      const { name } = currentRow;
      for (const key in this.tempRowData) {
        const parent = key.split(this.delimiter)?.[1] || null;
        if (parent === name) {
          this.tempRowData[key][currentColumn] = null;
        }
      }
    },
    updateLocalTableRow(currentRow, currentColumn) {
      const { parentName } = currentRow;
      const localTableRow = this.tableInstance.localTableRow;
      for (const row of localTableRow) {
        if (parentName === row.name) {
          const sum = this.sumOfArray(parentName, currentColumn);
          row[currentColumn] = sum;
          if (!this.tempRowData[parentName]) {
            this.tempRowData[parentName] = {};
          }
          this.tempRowData[parentName][currentColumn] = sum;
        }
      }
    },
    forceUpdate() {
      this.tableInstance.localTableRow = cloneDeep(
        this.tableInstance.localTableRow
      );
    },
    sumOfArray(parentName, currentColumn) {
      let sum = 0;
      let doesValidValueExist = false;
      for (let key in this.tempRowData) {
        const parent = key.split(this.delimiter)?.[1] || null;
        if (parent === parentName) {
          if (this.tempRowData[key]?.[currentColumn]) {
            const value = parseInt(this.tempRowData[key]?.[currentColumn]);
            if (value >= 0) {
              doesValidValueExist = true;
            }
            sum += value;
          }
        }
      }
      return doesValidValueExist ? sum : null;
    }
  }
};
</script>
